import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import MainCard from '../../components/common/MainCard';
import { PageTitle } from '../permission/PermissionStyle';
import { useTheme } from '@emotion/react';
import Redirect from '../../assets/Redirect.png';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { LoadingButton } from '@mui/lab';
import { EmailTypography, FormInfoBox } from '../Auth/AuthStyle';
import OtpInput from 'react-otp-input-rc-17';
import GetAxiosInstance from '../../components/common/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserData } from '../../components/common/common';
import moment from 'moment';
export default function VerifyOTP() {
  let userData = getUserData();
  const theme = useTheme();
  let { state } = useLocation();
  const [verifiedOTP, setVerifiedOTP] = useState(false);
  const [externalAppConsentId, setExternalAppConsentId] = useState();
  const [loading, setLoading] = useState();
  const [otpExpiryDate, setOtpExpiryDate] = useState();
  const [timeDiff, setTimeDiff] = useState(0);
  useEffect(() => {
    setOtpExpiryDate(moment(state?.bankReferenceExpiry));
  }, [state]);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      const updatedCurrentDate = moment(); // Update the current date/time

      // Calculate the remaining time in milliseconds
      const updatedTimeDiff = otpExpiryDate.diff(updatedCurrentDate);

      // Update the remaining time
      setTimeDiff(updatedTimeDiff);

      // Check if the OTP has expired
      if (updatedTimeDiff <= 0) {
        clearInterval(timerInterval); // Stop the timer
        toast.error('OTP has been expired.');
      }
    }, 1000); // Update every second

    return () => clearInterval(timerInterval); // Cleanup on unmount
  }, [otpExpiryDate]);

  // Calculate remaining hours, minutes, and seconds
  const remainingTime = moment.duration(timeDiff);
  const minutes = remainingTime.minutes();
  const seconds = remainingTime.seconds();
  const navigate = useNavigate();

  const [otp, setOtp] = useState();

  useEffect(() => {
    if (sessionStorage.getItem('eDoc:ExternalAppConsentId') !== 'undefined') {
      setExternalAppConsentId(
        sessionStorage.getItem('eDoc:ExternalAppConsentId')
      );
    }
  }, []);

  useEffect(() => {
    if (verifiedOTP) {
      const payload = {
        consentId: state?.consentId,
      };
      if (state?.verificationType === 3) {
        payload.transactionInfo = { transactionOtp: otp };
      }
      GetAxiosInstance(
        'orchestrator/get-transactions',
        'post',
        payload,
        userData.accessToken
      ).then((res) => {
        if (res?.data?.codes === 200) {
          let postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.TRANSACTIONS',
            status: 'SUCCESS',
            uniqueIdentifier: state?.consentId,
            subIdentifier: state?.bankName,
            data: {
              req: payload,
              res: res?.data?.data,
            },
          };
          if (externalAppConsentId) {
            postObj['source'] = 'EXTERNAL_APP';
          } else if (userData && userData?.userType !== 'nonGuest') {
            postObj['source'] = 'BUSINESS_PARTNER';
          } else postObj['source'] = 'CONNECT';

          GetAxiosInstance(`log/eventLog`, 'post', postObj);
          setLoading(false);
          if (userData && userData?.userType === 'nonGuest') {
            toast.success(res?.data?.message);
            navigate('/dashboard', {
              state: { ...res.data?.data, ...state },
            });
          } else if (externalAppConsentId) {
            navigate(
              `/consent-request/${externalAppConsentId}/consent-success`,
              {
                state: {
                  ...res.data?.data,
                },
              }
            );
          } else {
            navigate('/consent-success');
          }
        } else {
          let postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.TRANSACTIONS',
            status: 'ERROR',
            uniqueIdentifier: state?.consentId,
            subIdentifier: state?.bankName,
            data: {
              req: payload,
              res: res?.data?.data,
            },
          };
          if (externalAppConsentId) {
            postObj['source'] = 'EXTERNAL_APP';
          } else if (userData && userData?.userType !== 'nonGuest') {
            postObj['source'] = 'BUSINESS_PARTNER';
          } else postObj['source'] = 'CONNECT';

          GetAxiosInstance(`log/eventLog`, 'post', postObj);
          setLoading(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
  }, [verifiedOTP]);
  const handleClick = () => {
    setLoading(true);
    if (state?.verificationType === 2) {
      const obj = {
        consentId: state?.consentId,
        verificationCode: otp,
      };
      GetAxiosInstance(`orchestrator/verify-consent`, 'post', obj, {
        accessToken: state.accessToken,
      }).then((res) => {
        if (res?.data?.codes === 200) {
          let postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.VERIFICATION',
            status: 'SUCCESS',
            uniqueIdentifier: state?.consentId,
            subIdentifier: state?.bankName,
            data: {
              req: obj,
              res: res?.data?.data,
            },
          };
          if (externalAppConsentId) {
            postObj['source'] = 'EXTERNAL_APP';
          } else if (userData && userData?.userType !== 'nonGuest') {
            postObj['source'] = 'BUSINESS_PARTNER';
          } else postObj['source'] = 'CONNECT';

          GetAxiosInstance(`log/eventLog`, 'post', postObj);

          setVerifiedOTP(true);
        } else {
          let postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.VERIFICATION',
            status: 'ERROR',
            uniqueIdentifier: state?.consentId,
            subIdentifier: state?.bankName,
            data: {
              req: obj,
              res: res?.response?.data?.message,
            },
          };

          if (externalAppConsentId) {
            postObj['source'] = 'EXTERNAL_APP';
          } else if (userData && userData?.userType !== 'nonGuest') {
            postObj['source'] = 'BUSINESS_PARTNER';
          } else postObj['source'] = 'CONNECT';

          GetAxiosInstance(`log/eventLog`, 'post', postObj);

          setLoading(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    } else if (state?.verificationType === 3) {
      setVerifiedOTP(true);
    }
  };
  return (
    <Box component={'div'}>
      <Dialog
        open={true}
        keepMounted
        aria-labelledby="alert-dialog-slide-title1"
        aria-describedby="alert-dialog-slide-description1"
        bodystyle={{ backgroundColor: 'rgba(0,0,0)' }}
        className="white-bg-container"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
            borderRadius: '12px',
            padding: 4,
            maxWidth: 550,
            maxHeight: 460,
            width: '100%',
            height: '100%',
          },
        }}
      >
        <>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            {/* <Box component="img" src={Redirect} alt="account" /> */}
            <FormInfoBox>
              <Typography variant="Body_semibold_24">
                OTP verification
              </Typography>
              <EmailTypography>
                {state?.additionalInfo?.hintMessage ? (
                  <>{state?.additionalInfo?.hintMessage}</>
                ) : (
                  <>
                    We have sent a {state?.verificationType === 2 && '6'} digit
                    OTP code to your email address. Kindly input to verify and
                    continue
                  </>
                )}
              </EmailTypography>
            </FormInfoBox>
            <Stack>
              <Grid item xs={12}>
                <OtpInput
                  value={otp}
                  onChange={(otpNumber) => setOtp(otpNumber)}
                  numInputs={6}
                  className="otp-input"
                  containerStyle={{ justifyContent: 'space-between' }}
                  inputStyle={{
                    width: '100%',
                    height: '50px',
                    color: 'white',
                    margin: 8,
                    backgroundColor: theme.palette.primary.main,
                    padding: '10px',
                    border: 'none',
                    borderRadius: 12,
                    ':hover': {
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  focusStyle={{
                    outline: 'none',
                    border: 'none',
                  }}
                />
              </Grid>
            </Stack>
            <AnimateButton>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                sx={{ minWidth: { xs: 'auto', sm: '450px' } }}
                onClick={handleClick}
                loadingIndicator={
                  <CircularProgress
                    style={{
                      padding: '60px',
                      color: theme.palette.orange.main,
                    }}
                  />
                }
                loading={loading}
              >
                Verify otp
              </LoadingButton>
            </AnimateButton>
            0{minutes}:{seconds}
          </DialogContent>
        </>
      </Dialog>
    </Box>
  );
}
